<template>
  <form @submit.prevent="saveMOT" class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Test Number<span class="text-red-400">*</span></label>
      <input v-model="mot.test_number" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Test Number..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Test Date<span class="text-red-400">*</span></label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="mot.test_date"
        format="dd/MM/yyyy"
        @selected="startDateSelected()"
        placeholder="Test Date..."
      ></datepicker>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Expiry Date<span class="text-red-400">*</span></label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="mot.expiry_date"
        :open-date="openEndDate"
        :disabledDates="disabledEndDates"
        format="dd/MM/yyyy"
        @closed="endDateClosed()"
        placeholder="Expiry Date..."
      ></datepicker>
    </div>

    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Mileage<span class="text-red-400">*</span></label>
      <input v-model="mot.mileage_on_test" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Mileage..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Result</label>
      <input v-model="mot.result" type="text" class="w-full bge-input bge-input-spacing rounded" placeholder="Result..." />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Reminder Date<span class="text-red-400">*</span></label>

      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="mot.reminder_date"
        use-utc="false"
        format="dd/MM/yyyy"
        placeholder="Reminder Date..."
      >
      </datepicker>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Notes</label>
      <textarea v-model="mot.notes" type="text" class="w-full bge-input bge-input-spacing rounded" rows="5" placeholder="Notes..." />
    </div>
    <div class="w-full">
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          type="button"
          @click="saveMOT"
          class="
          float-right
          mt-5
          flex
          items-center
          bg-green-200
          hover:bg-green-300
          py-1
          px-3
          rounded
          border border-green-400
          hover:border-green-500
          text-secondary
          hover:shadow
          active:shadow-inner
          transition
          duration-100
        "
        >
          <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Save MOT</span>
        </button>
      </is-authorized>
    </div>
  </form>
</template>

<script>
const Datepicker = () => import("vuejs-datepicker");
import futureDateByNMonth from "@/utils/futureDateByNMonth";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "AddEditMOT",
  components: {
    Datepicker,
    IsAuthorized,
  },
  data() {
    return {
      openEndDate: new Date(),
      disabledEndDates: {
        to: new Date(Date.now() - 8640000),
      },
    };
  },
  props: {
    mot: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    if (this.mot.test_date) {
      this.openEndDate = new Date(this.mot.test_date);
      this.disabledEndDates.to = new Date(this.mot.test_date);
    }
  },
  methods: {
    startDateSelected(e) {
      this.$nextTick(() => {
        this.openEndDate = new Date(this.mot.test_date);
        this.disabledEndDates.to = new Date(this.mot.test_date);
      });
    },
    endDateClosed(e) {
      var reminderDate = this.$moment(this.mot.expiry_date)
        .subtract(31, "d")
        .toDate();

      if (
        this.$validator.isBefore(
          reminderDate instanceof Date ? reminderDate.toDateString() : reminderDate,
          this.mot.test_date instanceof Date ? this.mot.test_date.toDateString() : this.mot.test_date
        )
      ) {
        this.mot.reminder_date = this.$moment(this.mot.expiry_date)
          .subtract(1, "d")
          .toDate();
      } else {
        this.mot.reminder_date = reminderDate;
      }
    },
    saveMOT: function() {
      this.$emit("complete", this.mot);
    },
    deleteMOT: function() {
      this.$emit("delete", this.mot);
    },
  },
};
</script>
<style></style>
